/**
 * Renders the team page
 *
 * @flow
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import type { Meta } from '../types';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import { Container, IntroText } from '../styles';
import type { Member } from '../components/TeamMember';
import TeamMember from '../components/TeamMember';

const StyledImg = styled(Img)``;

const HeroSection = styled.div`
  margin-bottom: 99px;
  background: #fffbe9;

  ${Container} {
    position: relative;
    padding-top: 39px;
    padding-bottom: 375px;
  }

  ${StyledImg} {
    position: absolute !important;
    bottom: -39px;
    left: 50%;
    width: 546px;
    height: 436px;
    transform: translateX(-50%);
  }

  h1 {
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    margin-bottom: 144px;

    ${Container} {
      padding-top: 57px;
      padding-bottom: 584px;
    }

    ${StyledImg} {
      width: 900px;
      height: 718px;
      bottom: -88px;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 133px;

    ${Container} {
      padding-top: 94px;
      padding-bottom: 164px;
    }

    ${StyledImg} {
      top: -25px;
      left: 41%;
      width: 852px;
      height: 680px;
      transform: none;
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 490px;
`;

type Props = {
  data: {
    page: {
      yoastMeta: Meta[],
      customMeta: {
        title: string,
        canonical: string,
      },
      acf: {
        team: Array<Member>,
      },
    },
    illustrationTeam: {
      childImageSharp: {
        fluid: any,
      },
    },
  }
}

const About = ({
  data: {
    page,
    illustrationTeam,
  },
}: Props) => (
  <Layout fullwidth noHeaderBottomSpace>
    <SEO
      title={page.customMeta.title}
      canonical={page.customMeta.canonical}
      meta={page.yoastMeta}
    />

    <HeroSection>
      <Container>
        <HeroContent>
          <h1 className="h1-plus">Who are we?</h1>
          <IntroText>
            That is a good question!
          </IntroText>
          <IntroText>
            You could say we are a not-for-profit project based out of Print House,
            Dalston, London. Or… a couple of people, one cat, a dog and lots of
            bacteria trying to make the world a lovely place!
          </IntroText>
        </HeroContent>
        <StyledImg
          fluid={illustrationTeam.childImageSharp.fluid}
          alt=""
          loading="eager"
        />
      </Container>
    </HeroSection>

    <Container>
      <h2 className="h1">Our core team</h2>
      <IntroText>
        This is us, want to join or contribute in any way?
        Make sure to join the forum or contact us.
      </IntroText>
      <div className="row">
        {page.acf.team.map((member) => (
          <TeamMember className="col-sm-6 col-lg-4" member={member} />
        ))}
      </div>
    </Container>
  </Layout>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        page: wordpressPage(path: {eq: "/about/"}) {
          yoastMeta: yoast_meta {
            name
            property
            content
          }
          customMeta: et_custom_meta {
            title
            canonical
          }
          acf {
            team: team_members {
              bio
              department
              name
              photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 535) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              socialLinks: social_links {
                github
                instagram
                twitter
              }
            }
          }
        }
        illustrationTeam: file(relativePath: { eq: "illustration-team.png" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => <About data={data} />}
  />
);
