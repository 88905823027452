// @flow
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faGithub,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import Link from './Link';
import ScreenReaderText from './ScreenReaderText';

const Wrapper = styled.div`
  margin-top: 46px;

  @media (min-width: 768px) {
    margin-top: 80px;
  }

  @media (min-width: 992px) {
    margin-top: 101px;
  }
`;

const StyledImg = styled(Img)`
  border-radius: 4px;
  overflow: hidden;
`;

const Name = styled.div`
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 21px;
  }

  @media (min-width: 992px) {
    margin-top: 26px;
  }
`;

const Bio = styled.p`
  margin-top: 12px;
  font-size: 14px;
  color: #000;

  @media (min-width: 768px) {
    font-size: 19px;
  }
`;

const SocialLinks = styled.div`
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 31px;
  }
`;

const SocialLink = styled(Link).attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  font-size: 20px;
  box-shadow: none !important;

  & + & {
    margin-left: 22px;
  }
`;

export type Member = {
  bio: string,
  department: string,
  name: string,
  photo?: {
    localFile?: {
      childImageSharp: {
        fluid: any,
      },
    },
  },
  socialLinks?: {
    github: string,
    instagram: string,
    twitter: string,
  },
};

type Props = {
  member: Member,
  className?: string,
};

const defaultProps = {
  className: '',
};

const TeamMember = (props: Props) => {
  const {
    member: {
      name,
      department,
      bio,
      photo,
      socialLinks: {
        twitter,
        instagram,
        github,
      } = {},
    },
    className,
  } = props;

  return (
    <Wrapper className={className}>
      {photo && photo.localFile && (
        <StyledImg
          fluid={{ ...photo.localFile.childImageSharp.fluid, aspectRatio: 1.48 }}
          alt=""
        />
      )}
      <Name className="h2">{name}</Name>
      <div>{department}</div>
      <Bio>{bio}</Bio>
      <SocialLinks>
        <ScreenReaderText>Social profiles</ScreenReaderText>
        {twitter && (
          <SocialLink to={twitter}>
            <FontAwesomeIcon icon={faTwitter} />
            <ScreenReaderText>Twitter</ScreenReaderText>
          </SocialLink>
        )}
        {instagram && (
          <SocialLink to={instagram}>
            <FontAwesomeIcon icon={faInstagram} />
            <ScreenReaderText>Instagram</ScreenReaderText>
          </SocialLink>
        )}
        {github && (
          <SocialLink to={github}>
            <FontAwesomeIcon icon={faGithub} />
            <ScreenReaderText>Github</ScreenReaderText>
          </SocialLink>
        )}
      </SocialLinks>
    </Wrapper>
  );
};

TeamMember.defaultProps = defaultProps;

export default TeamMember;
